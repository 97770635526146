@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #001137;
  background-color: #000c25;
  overflow-x: hidden;
  /* opacity: 1;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #001137 11px ), repeating-linear-gradient( #002b8755, #002b87 ); */
}

.animate-delay {
  animation-delay: 0.5s;
}

.animate-delay-2 {
  animation-delay: 1s;
}

a {
  color: #fff;
  text-decoration: none;
}

p {
  font-family: 'Poppins';
  font-size: 18px;
  /* max-width: 600px; */
  font-weight: 600;
}

.header-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
}

h1 {
  font-size: 50px;
  margin: 0;
  position: relative;
  z-index: 99;
}

.blip {
  height: 50px;
  position: absolute;
  left: 220px;
  top: 25px;
}

.header h5 {
  position: absolute;
  left: 45px;
  top: 30px;
  font-family: 'Poppins';
  display: flex;
  align-items: center;
}

.rocket {
  height: 20px;
  margin-left: 6px;
}

.price-info-ctn {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
}

.price-block-ctn {
  text-align: center;
}

.price-block {
  background-color: rgba(255, 255, 255, 0.1);
  /* background-color: #1c2539; */
  padding: 20px 30px;
  text-align: center;
  line-height: 1.3em;
  font-size: 18px;
  margin: 0 10px;
  clip-path: polygon(100% 0, 100% 100%, 7% 100%, 0 89%, 0 0);
  position: relative;
  /* box-shadow: inset 0 0 14px 4px rgb(0 171 231 / 64%); */
  /* border: 2px solid #00abe7; */
}

.priceblock-icon {
  height: 44px;
  margin-bottom: -14px;
  z-index: 999;
  position: relative;
  /* display: none; */
}

.price-block span {
  font-weight: 700;
}

.priceblock-percent {
  font-size: 14px;
}

.priceblock-percent span {
  color: rgb(16, 185, 129);
}

.bar-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
}

.bar-ctn h3 {
  margin-bottom: 30px;
}

.bar {
  width: 90%;
  max-width: 600px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  border: 2px solid #00abe7;
  box-shadow: inset 0 0 14px 4px rgb(0 171 231 / 64%);
}

.inner-bar {
  height: 34px;
  background-color: #0074c2;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  /* background-image: linear-gradient(151deg, #CD0BC3, #FF21D0 53%, #FF21D0); */
  border-radius: 100px;
  position: relative;
  border: 1px solid #000;
  -webkit-transition: all 3s ease-in-out;
  -moz-transition: all 3s ease-in-out;
  -o-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  overflow: hidden;
}

.percentage-label {
  position: absolute;
  font-size: 11px;
  font-weight: 700;
  top: 8px;
  right: 32px;
}

.ecash-icon {
  height: 24px;
  padding: 5px;
  position: absolute;
  right: 0;
}

.flip-indicator {
  width: 1px;
  height: 36px;
  background-color: #fff;
  position: absolute;
}

.fi-0 {
  left: 0%;
  width: 1px;
  height: 36px;
  position: absolute;
}

.fi-1 {
  left: 20%;
}

.fi-2 {
  left: 40%;
}

.fi-3 {
  left: 60%;
}

.fi-4 {
  left: 80%;
}

.fi-5 {
  left: 100%;
  width: 1px;
  height: 36px;
  position: absolute;
}

.fi-1:after {
  content: '20%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-2:after {
  content: '40%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-3:after {
  content: '60%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-4:after {
  content: '80%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}
.fi-5:after {
  content: '100%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}

.fi-0:after {
  content: '0%';
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: -9px;
}

.bar-info-box {
  font-size: 14px;
  margin-top: 20px;
}

.bar-info-box span {
  font-weight: 700;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  margin-right: 2px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  margin-right: 2px;
}

/************************************************ CHART *****************************************************************/

.chart-ctn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 10px;
  margin-top: 50px;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 100px;
}

.row-logo {
  height: 60px;
}

.comparison-table {
  border-collapse: collapse;
  border: none;
  max-width: 600px;
  font-size: 14px;
}

.comparison-table td {
  border: 1px solid #000c25;
}

.comparison-table td:first-child {
  width: 40%;
  text-align: right;
}

.topRow td {
  border: none;
}

.color-cell td {
  background: rgba(255, 255, 255, 0.1);
}

.comparison-table .ecash-cell {
  /* background-color: #00abe7; */
  background-color: #0074c2;
  /* box-shadow: 0px 0px 15px 2px rgba(23,243,255,0.69); */
  /* background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7); */
}

.comparison-table .ecash-cell-border {
  /* border: 1px solid #0074C2; */
  box-shadow: inset 0px 0px 0px 1px #0074c2;
}

.loading {
  width: 100px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

/************************************************ Retrospective *****************************************************************/

.retrospective-ctn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 10px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;
  margin: 100px auto 100px;
}

.what-if-box {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px 45px;
  width: 100%;
  box-sizing: border-box;
}

.what-if-box h3 {
  margin: 0;
  font-weight: normal;
}

.select {
  margin: 20px 0;
}

.up-value {
  width: 100%;
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
  color: rgb(16, 185, 129);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  box-sizing: border-box;
}

.select2 {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
  margin: 20px 0;
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 600;
  position: relative;
  user-select: none;
}

.select2 .options-ctn {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  top: 45px;
  left: 0px;
  background-color: #000;
  z-index: 9999;
}

.select2 .options-ctn div {
  width: 100%;
  background-color: #1f2635;
  padding: 10px 0;
  cursor: pointer;
  margin-top: 1px;
}

.select2 .options-ctn div:hover {
  background-color: #0074c2;
}

.select2::after {
  content: '';
  width: 8px;
  height: 6px;
  background-color: #fff;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  position: absolute;
  right: 20px;
  top: 20px;
}

.learn-btn {
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  background-color: #0074c2;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  padding: 2px;
  margin-top: 30px;
}

.learn-btn div {
  padding: 10px 40px;
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  background-color: #0074c2;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
}

.learn-btn div:hover {
  background-color: #0074c2;
  padding: 10px 40px;
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  box-shadow: inset 0 0 13px 2px rgb(0 171 231 / 100%);
}

/************************************************ CUSTOM SELECT *****************************************************************/

:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

select::-ms-expand {
  display: none;
}

select {
  outline: none;
  color: #fff;
}

.select {
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 0;
  padding: 1em 0.5em;
  font-size: 16px;
  cursor: pointer;
  line-height: 1.1;
  background-color: rgba(255, 255, 255, 0.1);
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

select,
.select:after {
  grid-area: select;
  text-align: center;
  font-weight: 600;
}

.select::after {
  content: '';
  width: 8px;
  height: 6px;
  background-color: #fff;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

.what-if-box-header {
  display: flex;
  align-items: center;
}

.what-if-box-header div {
  width: 50%;
  padding: 5px 0;
  margin-right: 1px;
  margin-bottom: 1px;
  background-color: rgba(255, 255, 255, 0.15);
}

.what-if-box-row {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.what-if-box-row div {
  width: 50%;
  padding: 15px 0;
  margin-right: 1px;
  margin-bottom: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.what-if-box-row div svg {
  width: 6px;
  height: 6px;
  fill: #fff;
  margin-right: 4px;
}

.what-if-box-row div:last-child {
  /* background-color: rgb(16,185,129); */
  color: rgb(16, 185, 129);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

/*********************************** MODAL *****************************************/

.question {
  cursor: pointer;
  position: relative;
}

.question span {
  padding: 0px 6px;
  font-size: 12px;
  background-color: rgba(241, 241, 241, 0.1);
  border-radius: 100px;
}
.tool-tip {
  position: absolute;
  padding: 8px 10px;
  font-size: 14px;
  background: #1c2539;
  text-align: left;
  width: 250px;
  border: 1px solid #0074c2;
  left: 80%;
  z-index: 999;
}

/***********************************  *****************************************/

.graph-ctn {
  margin: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 30px;
}

.inner_graph-ctn {
  flex: 1;
  max-width: 700px;
  width: 100%;
}

.banner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0074c2;
  text-align: center;
  padding: 8px 20px;
  font-size: 14px;
  transition: all ease-in-out 200ms;
  box-sizing: border-box;
}

.banner:hover {
  background-color: #ff21d0;
}

/*********************************** MEDIA *****************************************/

@media (max-width: 800px) {
  .header-ctn {
    height: 150px;
  }
  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  h1 {
    font-size: 35px;
  }

  .blip {
    height: 40px;
    position: absolute;
    left: 40px;
    top: 10px;
  }

  .header h5 {
    position: absolute;
    top: 55px;
    margin: auto;
    left: 0;
    right: 0;
    justify-content: center;
  }

  .price-info-ctn {
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .price-block-ctn {
    display: flex;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .price-block {
    padding: 15px 20px;
    line-height: 1.3em;
    font-size: 16px;
    margin: 0px;
    width: 100%;
    max-width: 200px;
  }

  .priceblock-icon {
    width: 30px;
    height: unset;
    margin-bottom: -10px;
    display: none;
  }

  .bar-ctn {
    margin-top: 0;
  }

  .chart-ctn {
    padding: 0 10px;
    margin-top: 30px;
    font-size: 12px;
    margin-bottom: 100px;
  }

  .row-logo {
    height: 40px;
  }

  .comparison-table {
    font-size: 12px;
  }

  .comparison-table td {
    padding: 15px 5px;
  }

  .topRow td {
    padding: 5px 0;
  }

  .tool-tip {
    left: 0%;
  }

  .what-if-box {
    padding: 20px 20px;
    margin-bottom: 50px;
  }

  .learn-btn {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .select2 {
    padding: 10px 10px;
    font-size: 14px;
  }

  .select2 .options-ctn {
    font-size: 14px;
    top: 41px;
    left: 0px;
  }

  .banner {
    font-size: 12px;
    padding: 8px 10px;
  }
}
